import React from "react";

const NoPage = () => {
	return (
		<React.Fragment>
			<h2>No Page</h2>
		</React.Fragment>
	);
};

export default NoPage;
